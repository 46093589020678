export default {
  notificationMail: {
    label: 'Notification E-Mail',
    description: 'E-Mail address to send store notifications. If empty default laboratory mail will be used.',
  },
  orderNumber: 'Order number',
  orderNumberCurrent: 'Current number',
  orderNumberFormat: {
    label: 'Number format',
    description: 'Use {number} and {year} to format the order number',
  },
};
