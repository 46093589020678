import categories from './categories/messages';
import manufacturers from './manufacturers/messages';
import products from './products/messages';
import general from './general/messages';

export default {
  categories,
  manufacturers,
  products,
  general,
  included_doctors: 'Included doctors',
  excluded_doctors: 'Excluded doctors',
  isPublic: {
    label: 'Is public',
    description: 'You can add included or excluded doctors depending on public or private.',
  },
  permissionType: {
    public: 'Public',
    private: 'Private',
  },
};
