import { Modal } from '../Modal';
import { Alert, AlertProps } from './Alert';
import React from 'react';

export type AlertModalProps = AlertProps & { open?: boolean };

export const AlertModal: React.FC<AlertModalProps> = React.memo(({ open, ...alertProps }) => {

  return (
    <Modal
      narrow
      closable={false}
      footer={null}
      open={open}
      className={'alert-modal'}
    >
      <Alert {...alertProps}/>
    </Modal>
  );
});
