import { Feature, NotificationsPreferencesProperties, NotificationType, Product, UserProductsProperties } from 'interfaces/api';
import { filter } from 'lodash';
import { faBolt, faFileMedicalAlt, faFilePdf, faFilePlus } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useGuard } from 'containers';
import { NotificationItemProps, NotificationSettingProperties } from 'modules/notifications/interfaces';
import React, { useCallback, useMemo } from 'react';
import { useEnv } from 'providers/EnvProvider';
import { Icon } from 'components';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Color } from 'interfaces';

export const useNotificationItems = (
  preferences: NotificationsPreferencesProperties,
  userFeatures?: Record<Feature, boolean>,
  userProducts?: UserProductsProperties,
): NotificationItemProps[] => {

  const guard = useGuard();
  const APP_NAME = useEnv.APP_NAME();

  const enabledElement = useCallback((key: NotificationSettingProperties) => {
    return preferences[key].enabled
      ? filter([
        preferences[key].mail && getFeatureEnabled(Feature.Mails) && 'Mail',
        APP_NAME && preferences[key].push && 'Push',
        preferences[key].sms && getFeatureEnabled(Feature.Sms) && 'Sms',
      ]).join(', ')
      : (<>Aus</>);
  }, [preferences]);

  const getProductEnabled = useCallback((product: Product) => userProducts ? userProducts[product] : guard({ product }, () => true), [userProducts]);
  const getFeatureEnabled = useCallback((feature: Feature) => userFeatures ? userFeatures[feature] : guard({ feature }, () => true), [userFeatures]);

  return useMemo(() => filter([
    getProductEnabled(Product.OB) && {
      icon: faFileMedicalAlt,
      type: NotificationType.NewReport,
      title: messages.notifications.notificationTypes.NewReport,
      preferencesKey: 'newReportNotification',
      subtitle:
        <>
          {(getFeatureEnabled(Feature.ReportNotificationFilters)
            && (preferences.newReportNotificationSettings.characteristics.length === 0 || preferences.newReportNotificationSettings.status.length === 0))
            ? <Icon icon={faTriangleExclamation} color={Color.Yellow} style={{ marginRight: '0.25rem' }}/>
            : null}
          {enabledElement('newReportNotification')}
        </>,
      showExtra: true,
      hasWarning: getFeatureEnabled(Feature.ReportNotificationFilters)
        && (preferences.newReportNotificationSettings.characteristics.length === 0 || preferences.newReportNotificationSettings.status.length === 0),
    },

    getProductEnabled(Product.OB) && getFeatureEnabled(Feature.OriginalReport) && {
      icon: faFilePdf,
      title: messages.notifications.notificationTypes.NewAdditionalOriginalReport,
      type: NotificationType.NewAdditionalOriginalReport,
      preferencesKey: 'newAdditionalOriginalReport',
      subtitle: enabledElement('newAdditionalOriginalReport'),
    },

    getProductEnabled(Product.OB) && getFeatureEnabled(Feature.NotificationTrigger) && {
      icon: faBolt,
      title: messages.notifications.notificationTypes.NotificationTrigger,
      type: NotificationType.NotificationTrigger,
      preferencesKey: 'customTriggerNotification',
      subtitle: enabledElement('customTriggerNotification'),
    },

    getProductEnabled(Product.LE) && {
      icon: faFilePlus,
      title: messages.notifications.notificationTypes.NewExchangeFile,
      type: NotificationType.NewExchangeFile,
      preferencesKey: 'newExchangeFileNotification',
      subtitle: enabledElement('newExchangeFileNotification'),
    },

  ]), [preferences, getProductEnabled, getFeatureEnabled]);
};
