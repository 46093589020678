export default {
  label: 'Categories',
  fields: {
    name: 'Category name',
    parent: 'Parent category',
    path: 'Path',
    isPublic: 'Public',
  },
  controls: {
    add: {
      label: 'Add category',
    },
    edit: {
      label: 'Edit category',
    },
    delete: {
      label: 'Delete category',
      confirm: 'Confirm delete',
    },
  },
};
